import React, { useState, useEffect, useCallback } from 'react';
import { Button, SpinLoading, Toast, ResultPage, List, Avatar,ErrorBlock,Space } from 'antd-mobile'
import { getJsSdkConfigParams, authorizationDouYinAccount } from './server';

const { location } = window;
const url = location.href;

const timestamp = String(parseInt(Date.now() / 1000)); // 生成签名用的时间戳
const nonce_str = 'Wm3WZYTPz0wzccnW'; // 生成签名用的随机字符串
const jsb = ''; // 自定义测试用JSB方法名
const params = {}; // 自定义测试用JSB方法参数
function getPar(par) {
  //获取当前URL
  var local_url = document.location.href;
  //获取要取得的get参数位置
  var get = local_url.indexOf(par + "=");
  if (get === -1) {
    return false;
  }
  //截取字符串
  var get_par = local_url.slice(par.length + get + 1);
  //判断截取后的字符串是否还有其他get参数
  var nextPar = get_par.indexOf("&");
  if (nextPar !== -1) {
    get_par = get_par.slice(0, nextPar);
  }
  return get_par;
}
export default (props) => {
  const [sdk, setSdk] = useState(null);
  const [ready, setReady] = useState(false);
  const [sdkError, setSdkError] = useState(null);
  const [configStatus, setConfigStatus] = useState('');
  const [resultMsg, setResultMsg] = useState('');
  const [client_key, setClientKey] = useState('');
  const [msg, setMsg] = useState('');
  const [authInfo, setAuthInfo] = useState({});
  const [scopes, setScopes] = useState({
    'user_info': 0, // 0: 必选；1: 可选，默认不选中； 2: 可选，默认选中
    'alliance.kol.orders': 0,
  });
  const [authSuccess, setAuthSuccess] = useState(false);
  const [user, setUser] = useState({});
  // 打开原生授权页面JSB能力示例
  const onClickAuth = () => {
    if (ready) {
      setMsg(JSON.stringify({
        client_key,
        state: '',
        scopes: scopes,
        response_type: 'code'
      }))
      sdk.showOpenAuth({
        params: {
          client_key,
          state: '',
          scopes: scopes,
          response_type: 'code'
        },
        success: ({ ticket }) => {
          setResultMsg(`Auth Success: ${ticket}`)
          authorizationDouYinAccount({ 'code': ticket, 'qrcodeId': getPar('qrcodeId') }).then(res => {
            const { code = 0, msg = '授权失败', data = {} } = res.data
            const success = code === 1
            Toast.show({
              icon: success ? 'success' : 'fail',
              content: msg,
            })
            setAuthSuccess(success)
            setUser(data.user || {})
          })
        },
        error: res => setResultMsg(`Auth Error: ${JSON.stringify(res)}`)
      });
    }
  };

  // 可在URL参数中自定义JSB方法名和参数以测试
  const onClickJSB = () => {
    if (ready) {
      sdk[jsb]({
        params,
        success: res => setResultMsg(`JSB ${jsb} Success: ${JSON.stringify(res)}`),
        error: res => setResultMsg(`JSB ${jsb} Error: ${JSON.stringify(res)}`)
      });
    }
  };


  // 使用JSB能力前，必须先通过Config验证签名
  const config = useCallback(async () => {
    console.log('url', url);
    const { data: result = {} } = await getJsSdkConfigParams({ timestamp, nonce_str, url,qrcodeId:getPar('qrcodeId') });
    setAuthInfo(result.data)
    const { client_key, signature, scopes } = result.data;
    setScopes(scopes)
    // const { client_key, signature } = await getConfigParams({ timestamp, nonce_str, url });
    sdk.config({
      params: {
        client_key,
        signature,
        timestamp,
        nonce_str,
        url
      }
    });
    setClientKey(client_key);
  }, [sdk]);

  useEffect(() => {
    setSdk(window.douyin_open);
    if (sdk) {
      setConfigStatus('SDK Loaded');
      config();
      sdk.ready(() => {
        setReady(true);
        setConfigStatus('SDK Config Ready');
      });
      sdk.error(res => {
        setConfigStatus(`SDK Config Error: ${JSON.stringify(res)}`);
        setSdkError(`SDK Config Error: ${JSON.stringify(res)}`)
      });
    }
  }, [sdk, config]);

  const scopeNames = [
    '获取你的联盟售卖订单数据',
    '获取你的橱窗管理权限（添加商品、删除商品和橱窗商品查询）',
    '获取你的带货口碑分和销量',
    '获取你的抖音头像、昵称',
    '获取你发布的抖音视频数据（播放数、点赞数、评论数、分享数）',
    '获取你发布的抖音视频列表',
    '获取你的百应ID',
    '获取你的商品佣金信息',
    '获取你的抖音帐号个人数据（粉丝数，主页访问数，视频发布、点赞、评论、分享、转发数据）',
    '获取判断是否关注抖音号能力',
    '获取你发布的抖音视频数据（播放数、点赞数、评论数、分享数）',
    '获取你的抖音帐号粉丝喜好数据',
    '查询用户的粉丝数据能力'
  ];
  return (
    <>
      {
        authSuccess ?
          <>
            <ResultPage
              status='success'
              title='授权成功'
              description='授权成功，请返回小程序即可'
              primaryButtonText='返回小程序'
              onPrimaryButtonClick={() => Toast.show('返回失败，请手动返回小程序')}
            >
              <ResultPage.Card style={{ height: 64, padding: 8 }}>
                <List>
                  <List.Item
                    prefix={<Avatar src={user.avatar || ''} />}
                    extra={'授权成功'}
                  >
                    {user.nickname || '奋斗龟学员'}
                  </List.Item>
                </List>
              </ResultPage.Card>
            </ResultPage>
          </>
          :
          <>
            {
              sdkError !== null ?
                <ErrorBlock
                  style={{
                    '--image-height': '150px',
                  }}
                  title='Sdk初始化失败'
                  description={
                    <span>
                     {sdkError}
                    </span>
                  }
                >
                  <Space wrap>
                    <Button onClick={() => { location.href = authInfo.pcAuthUrl || '' }}>使用低版本授权方式</Button>
                    <Button onClick={() => { location.reload() }} color='primary'>点击重试</Button>
                  </Space>
                </ErrorBlock>
                :
                <div className='auth-box'>
                  <h3>奋斗龟严选官申请：</h3>
                  <div className='scope-list-wrap'>
                    <ul className='client-scope'>
                      {
                        scopeNames.map((v, k) => <li key={k} className='scope-item'>
                          <span>{v}</span>
                        </li>)
                      }
                    </ul>
                  </div>
                  <p style={{ display: 'none' }}>{configStatus} </p>
                  <p style={{ display: 'none' }}>{msg}</p>
                  {
                    !ready ? <div className='center'><SpinLoading style={{ margin: '20px' }} />
                      <Button block onClick={() => { location.reload() }} className="link">{'点击重试'}</Button>
                    </div> : (
                      <>
                        <Button block color='primary' style={{ marginTop: '20px' }} onClick={onClickAuth} className="link">点击授权</Button>
                        {jsb && <p onClick={onClickJSB} className="link" style={{ textTransform: 'capitalize' }}>{jsb}</p>}
                      </>
                    )
                  }
                  <p style={{ display: 'none' }}>{resultMsg}</p>
                </div>
            }
          </>
      }
      <p className='footer'>@ 2004-2023 奋斗龟 All rights reserved</p>
    </>
  );
};